import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { currencyFormat } from '../../../../../utils/funcHelpers'
import _queryKeys from '../../core/_queryKeys'
import useAppRequests from '../../Hooks/useAppRequests'

const ConfirmPayTable = () => {
  const { client, opportunity } = useParams()
  const { getOpportunityReq } = useAppRequests()

  const {
    isLoading: fetchLoading,
    isError: fetchError,
    data: enrolledSummary,
    refetch
  } = useQuery(_queryKeys.getOpportunityReq, () => getOpportunityReq(client || '', Number(opportunity) || -1))

  const payments = enrolledSummary?.payment;
  let paidSoFar = 0;
  payments?.forEach(p => {
    if (p.amount && p.status != 'Declined') {
      paidSoFar += Number(p.amount);
    }
  });

  let remaining_balance = 0
  if (paidSoFar) {
    remaining_balance -= paidSoFar
  }

  const scholarship_list = enrolledSummary?.opportunity_detail
    ?.filter(item => (item.type === 'Scholarship' || item.type === 'Discount'))
    .sort((a,b) => a.id - b.id) || []

  if (enrolledSummary && enrolledSummary.opportunity_detail) {
    enrolledSummary.opportunity_detail.forEach(item => {
      remaining_balance += Number(item.price)
    })
  }

  return (
    <div className='mt-20'>
      <div className='mb-7 d-flex justify-content-between'>
        <h1 className='fs-bold fs-2x text-uppercase'>Confirm & Pay</h1>
      </div>
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed align-middle gs-0 gy-4' style={{ color: '#020038' }}>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold' style={{ background: '#e6e6e6'}}>
                <th><h2 className='ps-5' style={{ color: '#020038' }}>Programs</h2></th>
                <th><h3 style={{ color: '#020038' }}>Total Amount</h3></th>
                <th><h3 style={{ color: '#020038' }}>Deposits</h3></th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody className='fs-5'>
              {enrolledSummary?.product_list?.map((p) => (
                <tr key={p.id}>
                  <td>
                    <span className='d-block ps-5'>
                      {p.session ? p.session[0].program_session[0].name : p.program_summary}
                    </span>
                  </td>
                  <td>
                    <span className='d-block'>{currencyFormat.format(p.tuition)}</span>
                  </td>
                  <td>
                    <span className='d-block'>
                      {currencyFormat.format(p.due_upon_application)}
                    </span>
                  </td>
                </tr>
              ))}
              
              <tr>
                <td className='fw-bold ps-5'>Fee</td>
              </tr>
              {enrolledSummary?.opportunity_detail?.map((ext) => {
                return (
                  (ext.type == 'Extra') ?
                  <tr key={ext.id}>
                    <td><span className='d-block ps-5'>{ext.product_name}</span></td>
                    <td><span className='d-block'>{currencyFormat.format(ext.price)}</span></td>
                    <td><span className='text-dark d-block'>{ext.category ? currencyFormat.format(ext.price) : ''}</span></td>
                  </tr> : ''
                )})
              }

              <tr>
                <td className='fw-bold ps-5'>Paid so far:</td>
                <td>{`(${currencyFormat.format(paidSoFar)})`}</td>
              </tr>
            
              {scholarship_list?.length ? (
                <tr>
                  <td className='fw-bold ps-5'>Scholarship & Discount</td>
                  <td></td>
                  <td></td>
                </tr>
              ) : ''}
                
              {scholarship_list.length ? scholarship_list?.map((item: any, ind) => (
                <tr key={ind}>
                  <td className='ps-5'>{item.product_name}</td>
                  <td>{`(${currencyFormat.format(item.price).toString().substring(1)})`}</td>
                  <td>{item?.due_upon_application ? `(${currencyFormat.format(item.due_upon_application).toString().substring(1)})` : ''}</td>
                </tr>
              )) : ''}

              {remaining_balance ? (
                <tr style={{ background: '#e6e6e6', color: '#020038'}}>
                  <td className='ps-5'><h2 style={{ color: '#020038' }}>Total</h2></td>
                  <td>{currencyFormat.format(Number(remaining_balance || 0))}</td>
                  <td></td>
                </tr>
              ) : ''}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
    </div>
  )
}

export default ConfirmPayTable
