import React from 'react'
import {useMutation, useQuery} from 'react-query'
import _queryKeys from '../../../modules/application/core/_queryKeys'
import useAppRequests from '../../../modules/application/Hooks/useAppRequests'
import FilterField from '../../../modules/application/components/Shared/FilterField'
import ReactHtmlParser from 'react-html-parser'
import {Formik, Form, FormikValues, ErrorMessage} from 'formik'
import HiddenField from '../../../modules/application/components/Shared/HiddenField'
import {initsQuestionnaire, IPredepartureItem, questionnaireSchema} from '../../../modules/application/core/_models'
import {useAuth} from '../../../modules/auth'
import { useParams } from 'react-router-dom'
interface Props {
  predeparture: IPredepartureItem,
  opportunity: string,
}
const PreprogramQuestionare = ({predeparture, opportunity}:Props) => {
  const {currentUser} = useAuth()
  const {fetchPredetureInfoFormsReq, saveAndContinueLater} = useAppRequests()
  const {client} = useParams()
  const clientRoutes = client === '47821104' ? 'SSB' : client === '88562775' ? 'TTN' : 'GLA'
  const {
    isLoading: isFieldLoading,
    isError: isFieldError,
    data: fieldFormsData,
    refetch: refetchfieldFormsData,
  } = useQuery(_queryKeys.fetchPredetureInfoFormsReq, () =>
    fetchPredetureInfoFormsReq('questionnaire', 'questionnaire', 1)
  )
  console.log(fieldFormsData)
  fieldFormsData?.data.forEach((val) => {
    initsQuestionnaire.questionnaires[val.name] = val.answer
      ? val.field_type === 'checkbox'
        ? val.answer.split(',')
        : val.answer
      : ''
  })

  console.log('initsQuestionnaire', initsQuestionnaire)

  const {
    isLoading: isSaveLoading,
    isSuccess: isSaveSuccess,
    isError: isSaveResponseError,
    mutate: saveResponse,
  } = useMutation(saveAndContinueLater)

  const onSave = (values: any, actions: FormikValues) => {
    //console.log('onsave', values)
    values.questionnaires.predeparture_id = predeparture.id
    const inputs: any = {
      opportunity: currentUser?.currentOpportunity,
      ...values,
    }
    saveResponse(inputs, {
      onSuccess: (res) => {
        if (res.data) {
          refetchfieldFormsData()
        }
      },
      onError: (err) => {
        console.log(err)
      },
    })
  }

  if (isFieldError) {
    return (
      <div className='alert alert-danger d-flex align-items-center p-5 my-10'>
        <div className='d-flex flex-column'>
          <h5 className='mb-1'>Oops!</h5>
          <span>Something went wrong!</span>
        </div>
      </div>
    )
  }

  return (
    <>
      {isSaveSuccess && (
        <div className='alert alert-success d-flex align-items-center p-5 mb-10'>
          <div className='d-flex flex-column '>
            <span>Your information has been saved and submitted to {clientRoutes} for review.</span>
          </div>
        </div>
      )}
      {isSaveResponseError && (
        <div className='alert alert-danger d-flex align-items-center p-5 my-10'>
          <div className='d-flex flex-column'>
            <h5 className='mb-1'>Oops!</h5>
            <span>Could not process your forms!</span>
          </div>
        </div>
      )}
      {isSaveLoading && (
        <div id='splash-screen2' className='splash-screen2'>
          <div className='border rounded bg-white py-5 px-15'>
            <span className='fs-3 fw-bold'>Saving ...</span>
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </div>
        </div>
      )}
      <div className='card mb-5 mb-xl-8'>
        <div className='card-body'>
          <div className='card-header px-0 mb-10'>
            <h1>Student Impact Pre-Program Questionnaire</h1>
            <div>
              <p>
                GLA programs aim to help you realize your potential to transform the world and your
                role in it. In order for us to understand if we're achieving this mission, we ask
                you to reflect on the following questions prior to your participation on the program
                and again after your program. We'll also check in with you every few years to
                evaluate the impact of your GLA program on your life. This process will help us
                improve our programs to ensure we're achieving our mission and will provide space
                for you to reflect on how this experience may impact you personally.
              </p>
              <p>Please be honest in your answers.</p>
              <p>
                <i>** Your answers here will not impact your acceptance into the program</i>
              </p>
            </div>
          </div>
          <Formik
            validationSchema={questionnaireSchema}
            initialValues={initsQuestionnaire}
            onSubmit={onSave}
          >
            {({values, errors, setFieldValue}) => {
              return (
                <Form>
                  {fieldFormsData?.data.map((form, idx) => (
                    <div key={idx}>
                      {form.type === 'question' && (
                        <div className='col-lg-12 mb-7' key={idx}>
                          <label className={'form-label fw-bolder text-dark fs-6 required'}>
                            {ReactHtmlParser(form.label)}
                          </label>
                          <div className='fw-bold text-dark mb-2'>
                            <span>{form.helper_text}</span>
                          </div>
                          <FilterField form={form} prefixFieldName='questionnaires.' />
                          <div className='text-danger mt-2'>
                            <ErrorMessage name={'questionnaires.' + form.name} />
                          </div>
                        </div>
                      )}
                      <HiddenField
                        form={form}
                        currentFormikVal={values}
                        formsList={fieldFormsData.data}
                        objectName='questionnaires'
                        prefixFieldName='questionnaires.'
                      />
                    </div>
                  ))}
                  <div className='text-end'>
                    <button type='submit' className='btn btn-lg btn-primary me-3'>
                      {!isSaveLoading && (
                        <span className='indicator-label'>
                          Save{' '}
                          <i className='fa-regular fa-floppy-disk ms-2'></i>
                        </span>
                      )}
                      {isSaveLoading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </div>
      </div>
    </>
  )
}

export default PreprogramQuestionare
