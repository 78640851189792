import { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import { useAuth } from '../../auth'
import useAuthRequests from './../../auth/hooks/useAuthRequests'
import { updateDefaultStudentAPI, getSelectedStudentAPI,getAllStudentsAPI } from '../../../../network/api';
const StudentListComponent = () => {
    const [message, setMessage] = useState('')
    const { currentUser } = useAuth()
    const [selectedStudent, setSelectedStudent] = useState('')
    const [studentError, setStudentError] = useState(false)
    const changeRedirectOptionHandler = (e: React.ChangeEvent<HTMLSelectElement>): any => {
        setSelectedStudent(e.target.value);
    };
    useEffect(() => {
        getSelectedStudent();
        getAllStudents();
    }, []);
    const { getPrograms } = useAuthRequests()
    const {
        isLoading,
        isError,
        data: response } = useQuery(
            `getPrograms`,
            () => {
                return getPrograms()
            }
        )
    const handleSubmitDefaultStudent = (e: any) => {
        if (selectedStudent == '') {
            setStudentError(true)
            return;
        }
        let dataToSend = {
            studentId: selectedStudent
        }
        updateDefaultStudent(dataToSend)
    }
    const updateDefaultStudent = async (data: any) => {
        const response = await updateDefaultStudentAPI(data);
        if (response?.data?.status == true) {
            // setMappedDocuments(response?.data?.message)
        } else {

        }
    }
    const getSelectedStudent = async () => {
        const response = await getSelectedStudentAPI();
        if (response?.data?.status == true) {
            // setMappedDocuments(response?.data?.message)
        } else {

        }
    }
    const getAllStudents = async () => {
        const response = await getAllStudentsAPI();
        if (response?.data?.status == true) {
            // setMappedDocuments(response?.data?.message)
        } else {

        }
    }
    return (
        <div className='card mb-5 mb-xl-8'>
            <div className='card-body py-3'>
                <div className='text-gray-400 fw-bold fs-4'>
                    {/* New Here? <br />
                    Choose the program to enroll into: */}
                    <div className="row" style={{ padding: '30px 0px' }}>
                        <div className="col-sm-10">

                            <select
                                className='form-select form-select-lg form-select-solid'
                                onChange={changeRedirectOptionHandler}
                                defaultValue={selectedStudent}
                            >
                                <option value="">Choose a Student</option>
                                {response?.map((c, idx) => {
                                    if (c.name != 'terraEducation') {
                                        return (
                                            <option value={c.reference_id} key={idx}>
                                                {c.abbreviation}
                                            </option>
                                        )
                                    }
                                })}
                            </select>
                            {studentError ?
                                <span style={{ color: 'red' }}>Select Student to continue</span> : null}
                        </div>
                        <div className="col-sm-2">
                            <div style={{ display: 'flex',justifyContent: 'flex-end'}}>
                                <button className='btn btn-primary' onClick={(e) => { handleSubmitDefaultStudent(e) }}>Submit</button>
                            </div>
                        </div>
                    </div>


                </div>
            </div>

        </div>
    )
}

export default StudentListComponent
